<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Notifikasi</v-card-title
          >
          <v-card-text class="py-0 px-0">
            <div class="px-4 d-flex justify-space-between pb-3">
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || parseInt(count) < 1"
                  text
                  small
                  @click="readAll()"
                >
                  <v-icon size="16" color="#3498db"> mdi-bell </v-icon>
                  <span class="hidden-sm-and-down ml-1">Baca Semua Notifikasi</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-card flat min-height="calc(100vh - 330px)">
              <v-skeleton-loader
                class="ma-auto"
                :loading="loading"
                :tile="false"
                type="list-item-avatar"
                v-for="(item, index) in loading ? 10:list" 
                :key="index">
                <div>
                  <v-card flat @click="toRead(item)" class="px-4">
                    <div class="d-flex align-center mb-4">
                      <v-card flat>
                        <!-- <v-avatar 
                          :size="$vuetify.breakpoint.name !== 'sm' && $vuetify.breakpoint.name !== 'xs' ? 40 : 30"
                          style="border: 3px solid #C05394; border-radius: 50%;"
                          class="cursor-pointer"
                          v-if="item.notification_content_jsonobject">
                          <v-img
                            :src="item.notification_content_jsonobject.data.user_image_url"
                            :alt="item.notification_content_jsonobject.data.user_username">
                          </v-img>
                        </v-avatar> -->
                        <v-btn
                          fab
                          text
                          width="45"
                          height="45">
                          <v-icon size="40" :color="item.read_bool ? 'grey' : 'yellow darken-4'">mdi-bell</v-icon>
                        </v-btn>
                      </v-card>
                      <div class="ml-4 mr-3">
                        {{ item.title }}
                        <span class="font-weight-bold cursor-pointer"> 
                          {{ item.text }}
                        </span>
                        <div class="caption">
                          <span class="grey--text">{{ item.created_datetime | datetime }}</span>
                        </div>
                      </div>
                      <!-- <v-img
                        max-width="50px"
                        min-width="50px"
                        height="45"
                        class="rounded ml-auto cursor-pointer"
                        v-if="item.notification_content_jsonobject && item.notification_content_jsonobject.type !== 'follow'"
                        :src="item.notification_content_jsonobject.data.content_image"
                        :lazy-src="item.notification_content_jsonobject.data.content_image">
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center">
                            <v-progress-circular
                              indeterminate
                              color="grey lighten-5"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img> -->
                    </div>
                  </v-card>
                </div>
              </v-skeleton-loader>
            </v-card>
            <div class="py-3 text-center">
              <v-chip
                color="white"
                text-color="green"
                v-show="loader"
              >
                <v-progress-circular
                  indeterminate
                  size="16"
                  width="2"
                  class="mr-4"
                  color="green"
                ></v-progress-circular>
                Memuat data...
              </v-chip>
              <v-btn
                v-show="
                  !loader &&
                  pagination.current < pagination.total_page
                "
                @click="loader = true; page = page+1; fetchData(page)"
                depressed
                small
                class="text-none white--text"
                color="green"
                >Tampilkan lagi
                <v-icon right small>mdi-arrow-down</v-icon></v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TokenService } from "@/services/service.js";
export default {
  name: "notification",

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Notifikasi",
        disabled: true,
        href: "/notification",
      },
    ],

    loading: false,
    loader: false,
    page: 1,
    count: 0,
    list: [],
    pagination: {},
    organizationData: [],
  }),
  mounted() {
    this.fetchData();
  },

  methods: {
    async fetchData(page) {
      this.loading = this.loader ? false :  true;

      let params = {
        limit: page === undefined ? 10 : page * 10,
        sort: "-id",
      };

      let response = await this.$get("notification", params);

      // this.loading = false;

      if (response.status == 200) {
        this.list = response.results.data;
        this.pagination = response.results.pagination
        this.loader = this.loader ? false : this.loader
        if (!this.loader) {
          this.fetchCountNotification()
        } else {
          this.loading = false
        }
      } else {
        this.loading = false
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async fetchCountNotification() {
      this.loading = true;

      let response = await this.$get("notification/count");

      this.loading = false;

      if (response.status == 200) {
        this.count = response.results.data.count_notification_unread;
        TokenService.saveNotif(response.results.data.count_notification_unread);
        this.$store.commit("setNotif", TokenService.getNotif());
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async toRead(item) {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Menuju Halaman Approval Program Diskon...";
      let id = parseInt(item.id)
      let response = await this.$post(`notification/read_bulk`, 
      {
        notification_id_array: [id]
      })
      this.$store.state.overlay.show = false;
      if (response.status == 200) {
        this.toLink(item)
        this.fetchData()
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },

    toLink(item) {
      if (item.from === 'discount_program') {
        window.location = `/approval-promo-discount?data=${item.from_id}`
      }
      this.$store.state.snackbar = {
        show: true,
        text: "Berhasil Menuju Halaman Approval Program Diskon",
        color: "#2ecc71",
      };
    },

    async readAll() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Membaca Semua Notifikasi...";
      let response = await this.$post(`notification/read_all`)
      this.$store.state.overlay.show = false;
      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil Membaca Semua Notifikasi",
          color: "#2ecc71",
        };
        this.fetchData()
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: response.message,
          color: "#e74c3c",
        };
      }
    },
  },
};
</script>

<style>
</style>